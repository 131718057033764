import React from 'react';

import { CowsCopyReasonEnum } from '@graphql-types';

import { Badge } from '~/shared/components/Badge';
import { TextLink } from '~/shared/components/TextLink';
import { Tooltip } from '~/shared/components/Tooltip';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { useConfirm } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { CowStateBadge, formatCow } from '~/entities/cows';
import { CowShortFragment } from '~/entities/cows/gql/fragments/cowShort.graphql';

import { useDeleteCowsFromCowsCopyKeyMutation } from '../../../../gql/mutations/deleteCowsFromCowsCopyKey.graphql';
import { updateCowsCopyKeyFragment } from '../../../../helpers';
import { MoveCowCard } from '../../../MoveCowCard';
import styles from './index.module.scss';

const COW_NAME_TOOLTIP_MAX_WIDTH_PX = 264;

interface Props {
  /**
   * cows to move with moveKey
   */
  cows: CowShortFragment[];
  /**
   * moveKey key to move cows
   */
  moveKey: string;
  /**
   * Reason of copy cows
   */
  reason: CowsCopyReasonEnum;
}

export const NestedKeyCows: React.FC<Props> = ({ moveKey, reason, cows }) => {
  const confirmDelete = useConfirm();
  const { sendNeutralToast } = useNotifications();

  const [deleteCowsFromCowsCopyKey] = useDeleteCowsFromCowsCopyKeyMutation();

  const handleDeleteCow = (cow: CowShortFragment) => async () => {
    const isConfirmed = await confirmDelete({
      title: 'Удаление животного из ключа',
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить животное{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatCow(cow, {
                prefix: '',
                withName: false,
              })}
            </Typography>
            ?
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Карточка животного вернётся к активному состоянию, в статусе,
            который был установлен до перемещения.
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      isDelete: true,
    });
    if (!isConfirmed) return;

    deleteCowsFromCowsCopyKey({
      variables: {
        key: moveKey,
        cowIDs: [cow.id],
      },
      optimisticResponse: { deleteCowsFromCowsCopyKey: null },
      update: updateCowsCopyKeyFragment(moveKey, draft => {
        draft.cows = draft.cows.filter(({ id }) => id !== cow.id);
      }),
    });
    sendNeutralToast('Животное удалено');
  };

  return (
    <div className="flex flex-col gap-12">
      <div className="flex gap-8">
        <Typography variant={TypographyVariants.bodySmallStrong}>
          {reason === CowsCopyReasonEnum.Sell
            ? 'Список животных со статусом на момент продажи'
            : 'Список животных со статусом на момент перемещения'}
        </Typography>
        <Badge isPill>{cows.length}</Badge>
      </div>
      {cows.map((cow, index) => (
        <MoveCowCard
          {...{
            key: cow.id,
            index: index + 1,
            contentItemsClassName: 'py-2',
            contentItems: [
              <div {...{ key: cow.id, className: styles.cowIdTextBlock }}>
                <Tooltip
                  {...{
                    content:
                      'Нажмите, чтобы открыть карточку животного в новой вкладке',
                    maxWidth: COW_NAME_TOOLTIP_MAX_WIDTH_PX,
                  }}
                >
                  <TextLink
                    {...{
                      key: cow.id,
                      target: '_blank',
                      to: '/$companyId/user/cows/$cowId',
                      params: prev => ({
                        ...prev,
                        cowId: cow.id,
                      }),
                    }}
                  >
                    {formatCow(cow, { prefix: '', withName: true })}
                  </TextLink>
                </Tooltip>
              </div>,
              <CowStateBadge
                isFull
                key={cow.state}
                state={cow.previousState ?? cow.state}
              />,
            ],
            onDelete: cows.length > 1 ? handleDeleteCow(cow) : undefined,
          }}
        />
      ))}
    </div>
  );
};
