import { ReactNode } from 'react';

import { FloatingFocusManagerProps } from '@floating-ui/react';
import { SpringValue } from '@react-spring/web';

import { ButtonProps } from '~/shared/components/Button';

import { ModalNames } from './__generated__/modalNames';

/**
 * Base type for modal component props
 */
export type AnyModalProps = Record<string, any>;

/**
 * Modal component receives some additional props.
 */
export interface InjectedModalProps<P extends AnyModalProps | undefined> {
  /**
   * Close action
   */
  close: () => void;
  /**
   * Open action
   */
  open: (
    overrideProps?: P extends undefined ? P : Partial<P> | undefined
  ) => void;
  /**
   * Current step number in the modal
   */
  stepNumber: number;
  /**
   * Action to set current modal stepNumber
   */
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
  /**
   * Action to increase step number by 1
   */
  goToNextStep: () => void;
  /**
   * Action to decrease step number by 1
   */
  goToPrevStep: () => void;
}

/**
 * Object used to register a modal.
 */
export interface ModalRegistration<P extends AnyModalProps> {
  Component: React.FC<P>;
}

/**
 * A dictionary of modals with registration options obtained via require.context and is used for module init.
 */
export type ModalRegistrationsDict = Record<
  ModalNames,
  ModalRegistration<AnyModalProps>
>;

/**
 * Possible sizes for a modal
 */
export enum ModalSizes {
  small516 = 'small516',
  medium950 = 'medium950',
  large1192 = 'large1192',
}

/**
 * Props for the Modal component
 */
export interface ModalComponentProps extends React.PropsWithChildren {
  /**
   * className applied to the modal element
   */
  className?: string;
  /**
   * className applied to the modal content wrapper div (the actual children of the component)
   */
  contentClassName?: string;
  /**
   * Size for the modal
   */
  size?: ModalSizes;
  /**
   * Modal title to show in header
   */
  title?: ReactNode;
  /**
   * Modal subtitle to show in header
   */
  subtitle?: ReactNode;
  /**
   * Count of steps in the modal to render in the corner with buttons
   */
  stepsCount?: number;
  /**
   * Current step number in the modal
   */
  stepNumber?: number;
  /**
   * If true, you must press (x) to close the modal
   * and confirm the loss of data before leaving the page
   */
  isRequireExplicitClosing?: boolean;
  /**
   * If true, blocked loading state is rendered instead of children
   */
  isLoading?: boolean;
  /**
   * Called when the submit button is pressed
   */
  onSubmit?: () => void;
  /**
   * If true, shows the submit button (default - true)
   */
  shouldShowSubmitButton?: boolean;
  /**
   * Additional props for submit button
   */
  submitButtonProps?: Partial<Omit<ButtonProps, 'ref'>>;
  /**
   * Called when the modal window is cancelled (via cancel button, overlay click or esc key)
   */
  onCancel?: () => void;
  /**
   * Called when the modal closes
   */
  onClose?: () => void;
  /**
   * Additional props for cancel button
   */
  cancelButtonProps?: Partial<Omit<ButtonProps, 'ref'>>;
  /**
   * Some additional buttons, rendered before default cancel and submit
   */
  additionalButtons?: [ReactNode];
  /**
   * If you need to render custom buttons, pass this prop, it replaces default save and cancel buttons
   */
  renderButtons?: () => ReactNode;
  /**
   * If true, the modal is shown with open animation (default - true)
   */
  withOpenAnimation?: boolean;
  /**
   * Props for focus management
   */
  floatingFocusManagerProps?: Partial<FloatingFocusManagerProps>;
}

/**
 * State of a single modal
 */
interface SingleModalState<P extends AnyModalProps> {
  /**
   * If true, the modal is visible
   */
  isOpen: boolean;
  /**
   * Used to handle zIndex if there are multiple modals
   */
  stackIndex: number;
  /**
   * Some modals can have multiple steps, which defines the behavior
   */
  stepNumber: number;
  /**
   * Props are passed to the inner component
   */
  props?: P;
}

/**
 * Dictionary of modals with their states.
 */
export type ModalsState = Record<ModalNames, SingleModalState<AnyModalProps>>;

/**
 * Type of value for ModalContextProvider
 */
export interface ModalsContextType {
  state: ModalsState;
  openModal: <P extends AnyModalProps>(name: ModalNames, props?: P) => void;
  closeModal: (name: ModalNames) => void;
  setModalStep: (name: ModalNames, stepNumber: number) => void;
}

/**
 * Transition styles for modal component
 */
type ModalTransitionStyles = Record<string, SpringValue<any>>;

/**
 * Type of value for SingleModalContextProvider
 */
export interface SingleModalContextType {
  modalName: ModalNames;
  transitionStyles: ModalTransitionStyles;
}
