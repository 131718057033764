import { useRouter } from '@tanstack/react-router';

import { AnyRouteItem } from '~/services/navigation';

/**
 * Hook for exact matching route.
 * Tanstack matchRoute doesn't ignore leading slash
 */
export const useExactMatch = () => {
  const { matchRoute } = useRouter();

  return (locationPath: AnyRouteItem): boolean => {
    const matchedRoute = matchRoute({ to: locationPath }, { fuzzy: true });

    return (
      matchedRoute &&
      // Property '**' contains the difference between the current route and
      // the passed locationPath, if the current one is longer. The
      // property is optional and will be absent if the routes
      // completely match.
      (('**' in matchedRoute && matchedRoute['**'] === '/') ||
        !('**' in matchedRoute))
    );
  };
};
