import React from 'react';

import { Link } from '@tanstack/react-router';
import clsx from 'clsx';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import styles from './index.module.scss';
import { TabsProps, TabsVariants } from './types';

export const Tabs: React.FC<TabsProps> = ({
  className,
  variant = TabsVariants.primary,
  children,
  tabs,
}) => {
  return (
    <>
      <div className={clsx(styles.root, styles[variant], className)}>
        {tabs.map(tabConfig => {
          const tabContent = (isActive: boolean) => (
            <Typography
              variant={
                isActive && variant === TabsVariants.primary
                  ? TypographyVariants.heading5
                  : TypographyVariants.bodySmall
              }
            >
              {tabConfig.title}
            </Typography>
          );

          return (
            <Link
              {...{
                to: tabConfig.href,
                className: styles.tab,
                activeProps: {
                  className: styles.active,
                },
                resetScroll: false,
                params: true,
                search: true,
              }}
            >
              {({ isActive }) => tabContent(isActive)}
            </Link>
          );
        })}
      </div>
      {children}
    </>
  );
};

export * from './types';
