import { useRef, useState } from 'react';

import { useResizeObserver } from '@react-aria/utils';

/**
 * Possible axes to check overflow on
 */
export enum OverflowAxes {
  all = 'all',
  x = 'x',
  y = 'y',
}

const ElementDimensionKeysByAxis: Record<
  OverflowAxes,
  [keyof HTMLElement, keyof HTMLElement][]
> = {
  [OverflowAxes.all]: [
    ['scrollWidth', 'clientWidth'],
    ['scrollHeight', 'clientHeight'],
  ],
  [OverflowAxes.x]: [['scrollWidth', 'clientWidth']],
  [OverflowAxes.y]: [['scrollHeight', 'clientHeight']],
};

/**
 * It returns a ref and a boolean value that indicates
 * whether the element is overflowing in the specified axis.
 * @param axis - The axis to check for overflow.
 * @param checkDelayMs - If we're using some animation, we can pass a delay to retry the overflow check
 * @returns An object with a ref and isOverflow property.
 */
export const useIsOverflow = <T extends HTMLElement = HTMLDivElement>(
  axis = OverflowAxes.x,
  checkDelayMs = 0
) => {
  const ref = useRef<T>(null);

  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useResizeObserver({
    ref,
    onResize: () => {
      const checkOverflow = () => {
        const { current } = ref;

        if (!current) return;

        const hasOverflow = ElementDimensionKeysByAxis[axis].some(
          ([naturalSizeKey, overflowSizeKey]) =>
            (current[naturalSizeKey] ?? 0) > (current[overflowSizeKey] ?? 0)
        );

        setIsOverflow(hasOverflow);
      };

      checkOverflow();
      // Check multiple times for potential animation issues
      setTimeout(checkOverflow, 0);
      setTimeout(checkOverflow, checkDelayMs / 2);
      setTimeout(checkOverflow, checkDelayMs);
    },
  });

  return { ref, isOverflow };
};
