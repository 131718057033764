import { useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';

import { BlueprintLaunchInput } from '@graphql-types';

import { useArkaNavigation } from '~/services/navigation';

import { CustomReportFragment } from '~/entities/customReports/gql/fragments/customReport.graphql';
import { CustomReportDetailedFragment } from '~/entities/customReports/gql/fragments/customReportDetailed.graphql';

import { CustomReportLaunchResultFragment } from '../gql/fragments/customReportLaunchResult.graphql';
import { useLaunchCustomReportMutation } from '../gql/mutations/launchCustomReport.graphql';

interface UseLaunchCustomReportProps {
  /**
   * Custom report to launch
   */
  customReport: CustomReportFragment | CustomReportDetailedFragment | undefined;
  /**
   * hashID of the current report launch
   */
  launchHashId?: string;
  /**
   * We may have already have initial launch state from routing
   */
  initialLaunchState?: CustomReportLaunchResultFragment;
}

export const useLaunchCustomReport = ({
  customReport,
  launchHashId,
  initialLaunchState,
}: UseLaunchCustomReportProps) => {
  const { navigate } = useArkaNavigation();

  const [customReportLaunchResult, setLaunchCustomReportResult] =
    useState(initialLaunchState);
  const [customReportLaunchErrors, setLaunchCustomReportErrors] =
    useState<GraphQLErrors>([]);

  // If we passed new initial launch state, it means we updated the report
  useEffect(() => {
    if (initialLaunchState) {
      setLaunchCustomReportResult(initialLaunchState);
      window.history.replaceState({}, '');
    }
  }, [initialLaunchState]);

  // Loading doesn't start in the same macro task after customReport load,
  // so we imitate loading from mount if we don't have an initialLaunchState
  const [isLaunchLoading, setIsLaunchLoading] = useState(!initialLaunchState);
  const [launchCustomReportMutation] = useLaunchCustomReportMutation();

  const abortControllerRef = useRef<AbortController>();

  const launchCustomReport = (inputs?: BlueprintLaunchInput[]) => {
    setIsLaunchLoading(true);
    abortControllerRef.current?.abort();

    abortControllerRef.current = new AbortController();

    return launchCustomReportMutation({
      variables: {
        id: launchHashId ? undefined : customReport?.id,
        hashID: launchHashId,
        inputs,
      },
      context: {
        fetchOptions: {
          signal: abortControllerRef.current.signal,
        },
        shouldSkipErrorNotifications: true,
      },
    })
      .catch((err: ApolloError) => {
        setLaunchCustomReportErrors(err.graphQLErrors);
      })
      .then(result => {
        const launchResult = result?.data?.launchCustomReport;
        if (!launchResult) {
          return undefined;
        }

        setLaunchCustomReportErrors([]);
        setLaunchCustomReportResult(launchResult);
        return launchResult;
      })
      .finally(() => {
        setIsLaunchLoading(false);
      });
  };

  // Launch custom report, if we doesn't have an initial state or inputs,
  // clear url state otherwise, so we don't have stale cache
  useEffect(() => {
    if (!customReport) return;

    if (initialLaunchState) {
      window.history.replaceState({}, '');
    } else if (!!launchHashId || !customReport.blueprint.inputs.length) {
      launchCustomReport().then(launchResult => {
        // If we run existing launch and it is not found, we should navigate back to custom report modal
        if (!launchResult && !!launchHashId) {
          navigate({ to: '..' });
        }
      });
    } else {
      setIsLaunchLoading(false);
    }
  }, [
    customReport?.id,
    customReport && 'settings' in customReport
      ? customReport.settings
      : undefined,
  ]);

  return {
    abortControllerRef,
    isLaunchLoading,
    launchCustomReport,
    customReportLaunchResult,
    setLaunchCustomReportResult,
    customReportLaunchErrors,
  };
};
