import React, { Suspense } from 'react';

import {
  createRootRouteWithContext,
  redirect,
  ScrollRestoration,
} from '@tanstack/react-router';
import { camelCase } from 'camel-case';

import { ErrorBoundary, MyUserWrapper, NotFoundPage } from '~/services/layouts';
import { ModalRegistrationsDict, ModalsProvider } from '~/services/modals';

interface RouterContext {
  auth: {
    isAuthenticated: boolean;
  };
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  errorComponent: ErrorBoundary,
  notFoundComponent: NotFoundPage,
  beforeLoad: ({
    context: {
      auth: { isAuthenticated },
    },
    location,
  }) => {
    if (!isAuthenticated && location.pathname.replace(/\/$/, '') !== '/login') {
      throw redirect({ to: '/login' });
    }
  },
});

// Modals are collected from all project to be registered and be available for reuse anywhere
const context = require.context('..', true, /\.modal\.tsx?$/);

const MODALS_PATHS: string[] = context.keys();

/*
 * Creating a dictionary of modals.
 */
const MODAL_REGISTRATIONS_DICT = MODALS_PATHS.reduce<ModalRegistrationsDict>(
  (acc, path) => {
    const modalName = camelCase(path.split('/').slice(-2, -1).toString());

    const options = context(path).registration;

    return {
      ...acc,
      [modalName]: options,
    };
  },
  {} as ModalRegistrationsDict
);

const TanStackRouterDevtools =
  process.env.REACT_APP_SHOULD_SHOW_ROUTER_DEVTOOLS === 'true'
    ? React.lazy(() =>
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        }))
      )
    : () => null;

function RootComponent() {
  return (
    <ModalsProvider modalRegistrationsDict={MODAL_REGISTRATIONS_DICT}>
      <MyUserWrapper />
      <ScrollRestoration />
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </ModalsProvider>
  );
}
