import React from 'react';
import { useTranslation } from 'react-i18next';

import { Outlet } from '@tanstack/react-router';

import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { useAuth } from '~/services/auth';
import { useMyUserSuspenseQuery } from '~/services/auth/gql/queries/myUser.graphql';
import { NavigateFromRootOutlet } from '~/services/layouts/components/MyUserWrapper/components';
import {
  useGoogleAnalytics,
  useSentry,
  useYandexMetrika,
} from '~/services/vendorAnalytics';

export const MyUserWrapper = () => {
  const { isAuthenticated } = useAuth();

  useTranslation([MaslovNamespaces.enums, MaslovNamespaces.validation]);

  useYandexMetrika();
  useGoogleAnalytics();
  useSentry();

  useMyUserSuspenseQuery({
    skip: !isAuthenticated,
  });

  if (isAuthenticated) {
    return <NavigateFromRootOutlet />;
  }

  return <Outlet />;
};
