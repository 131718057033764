import React, { Suspense } from 'react';

import { Outlet, useLocation } from '@tanstack/react-router';
import clsx from 'clsx';

import { Loader } from '~/shared/components/Loader';
import {
  TabConfig,
  Tabs,
  TabsProps,
  TabsVariants,
} from '~/shared/components/Tabs';

import styles from './index.module.scss';

interface RouterTabConfig extends Required<TabConfig> {
  /**
   * If passed along with href, the route is rendered with this path
   */
  path?: string;
}

export const RouterTabs: React.FC<TabsProps<RouterTabConfig>> = ({
  ...tabsProps
}) => {
  const { pathname } = useLocation();

  const { tabs, variant = TabsVariants.primary } = tabsProps;
  const firstTab = tabs[0];
  // This is heuristic detection that will work only
  // if we don't have same route parts repeated
  const activeTab =
    pathname.split('/').find(part => tabs.some(t => t.href === part)) ??
    firstTab.href;

  return (
    <Tabs
      {...{
        ...tabsProps,
        selectedTab: activeTab,
      }}
    >
      <div
        className={clsx(variant === TabsVariants.primary && styles.tabPanel)}
      >
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </div>
    </Tabs>
  );
};
